<template>
  <div
    class="player"
    :style="'width:' + widthHeigt[0] + '%' + 'height:' + widthHeigt[1] + '%'"
  >
    <div class="video" id="tcplayer" ref="tcplayer"></div>
  </div>
</template>

<script>
export default {
  name: "TcPlayer",
  components: {},
  props: {
    videoUrl: {
      type: Array,
    },
    widthHeigt: {
      type: Array,
      default: () => {
        return [300, 100];
      },
    },
  },
  data() {
    return {
      player: "",
    };
  },
  created() {
    console.log("videoUrl", this.videoUrl,this.videoUrl[0],this.videoUrl[1]);
  },
  watch: {
    playVideo: function (newVal) {
      this.play();
    },
  },
  mounted() {
    this.play();
  },
  methods: {
    play() {
      let dom = document.getElementById("tcplayer");
      // let dom = this.$refs.tcplayer;
      // console.log(dom);
      while (dom.hasChildNodes()) {
        //当div下还存在子节点时 循环继续
        dom.removeChild(dom.firstChild);
        // this.player.destroy();
      }
      console.log("视频宽高：",this.widthHeigt);
      // console.log(JSON.stringify(this.playVideo));
      this.player = new this.TcPlayer("tcplayer", {
        m3u8: this.videoUrl[0],
        flv: this.videoUrl[1],
        //  m3u8: "https://pull.yi-lian.net/aUqx5/XZFdh.m3u8?auth_key=1634585837-0-0-557cdcbea9e14480d146a6a7b17442d3",
        //   flv: "https://pull.yi-lian.net/aUqx5/XZFdh.flv?auth_key=1634585837-0-0-fe5980f2777d989a60029c28da24b8a1", //增加了一个flv的播放地址，用于PC平台的播放 请替换成实际可用的播放地址
        autoplay: true, //iOS 下 safari 浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
        flash: false,
        width: this.widthHeigt[0] +"%", //视频的显示宽度，请尽量使用视频分辨率宽度
        height: this.widthHeigt[1]+"%", //视频的显示高度，请尽量使用视频分辨率高度
        live: true,
        systemFullscreen: true,
        // controls: "none",
        wording: {
          2: "主播离开中。。。",
          1002: "连接网络不稳定，请切换清晰度",
        },
      });
    },
  },
  destroyed() {
    // 页面销毁，同时也销毁 TcPlayer
    this.player.destroy();
  },
};
</script>
<style lang="less" scoped>
.player {
  width: 100%;
  height: 100%;
  .video {
    width: 100%;
    height: 100%;
    .vcp-player {
      width: 100%;
      height: 100%;
      video {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
  }
}
</style>
