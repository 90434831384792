import $ from 'jquery'
// import wx from 'weixin-js-sdk';
import {
  getdetail,
  getMetDetail
} from "../../api/index";
import VTcPlayer from "../../components/vtcplayer/vtcplayer.vue";
import {
  wxChatShare
} from "../../utils/wechat"
export default {
  components: {
    VTcPlayer
  },
  data() {
    return {
      videoList: {},
      userinfo: {},
      isshare: false,
      isVtcshow: false,
      videoType: "",
      vidUrl: []
    };
  },
  created() {
    let _this = this
    this.videoType = this.$route.query.type
    var ua = navigator.userAgent.toLowerCase();
    if (sessionStorage.getItem("userinfo")) {
      this.userinfo = JSON.parse(sessionStorage.getItem("userinfo"))
      if (_this.videoType === "videodetail") {
        this.getDetailData().then(() => {
          console.log("getDetailData-videolist:",this.videoList);
          this.wechatShare()
        })
        $("#doctorVideo").volume = 0.5;
        this.$nextTick(() => {
          $('#doctorVideo').bind('contextmenu', function () {
            return false;
          });
        })
      } else if (_this.videoType === "meetingVideo") {
        this.getmeetingData().then(() => {
          console.log("getmeetingData-videolist:",this.videoList);
          this.wechatShare()
        })
        $("video").volume = 0.5;
      }
      // if (this.userinfo.isRealName === 1) {
      //   this.getDetailData();
      //   $("#doctorVideo").volume = 0.5;
      //   this.$nextTick(() => {
      //     $('#doctorVideo').bind('contextmenu', function () {
      //       return false;
      //     });
      //   })
      // } else {
      //   alert("未实名注册，无法观看")
      //   this.$router.push('/')
      // }
    } else {
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        window.location.href = "http://s.yi-lian.net"
      } else {
        this.$router.push("/login")
      }
    }
  },
  mounted() {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      this.isshare = true
    } else {
      this.isshare = false
      console.log("pc");
    }

  },
  methods: {
    wechatShare() {
      let params = {
        url: window.location.href,
        title: this.isVtcshow ? this.videoList.meetingName : this.videoList.title,
        link: (window.location.href).replace(window.location.search, ''),
        imgUrl: this.isVtcshow ? "https://yilian-biz.oss-cn-shanghai.aliyuncs.com//upload/20211112/03eab30b96b9997a72503b690552fb6e.jpeg" : this.videoList.imgUrl,
        desc: this.isVtcshow ? this.videoList.meetingName + "的实时会议" : this.videoList.content,
        type: "",
        dataUrl: ""
      }
      console.log("wechatShare",this.videoList,params);
      wxChatShare(params)
    },
    async getmeetingData() {
      let data = {
        id: this.$route.query.id
      }
      let result = await getMetDetail(data)
      if (result.code === 200) {
        this.videoList = result.data
        this.vidUrl = this.videoList.pullUrl.split(",")
        this.isVtcshow = true
      }
    },
    async getDetailData() {
      let id = this.$route.query.id;
      let result = await getdetail(id);
      this.videoList = result.data;
    },
    backto() {
      this.$router.push("/");
    },
    sharebtn() {
      let id = this.$route.query.id;
      this.$router.push({
        path: "/SharePage",
        query: {
          tourl: "/videoDetail",
          id: id,
          title: this.videoList.title
        }
      })
    }
  },
  watch: {
    videoType(a) {
      // a==="meetingVideo"&& this.vidUrl!=[]?this.isVtcshow=true:this.isVtcshow=false
    }
  },
  destoryed() {
    sessionStorage.removeItem("urlAll")
  }
};